import React from 'react';
import Button from '@material-ui/core/Button';
import Image from '../../assets/images/76x148.jpeg'; // Import using relative path
import { CardMedia, Typography } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { ArrowRightAlt } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { section } from '../../theme';
import {Translate} from 'react-auto-translate'

const HeroSection = () => {
  return (
    <>
      <CardMedia image={Image}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Box
            bgcolor="common.white"
            textAlign="center"
            p={4}
            mx={section.paddingX}
            borderRadius="5px"
            boxShadow="5"
            maxWidth='300px'
          >
            <Typography variant="h4" color="common.black">
              <Translate>Sacko Diéli Makan Art Work</Translate>
            </Typography>
          </Box>
          <Box mt="-1.15rem">
            <Link to="/paintings">
              <Button
                variant="contained"
                color='primary'
                endIcon={<ArrowRightAlt>send</ArrowRightAlt>}
              >
                <Translate>View Paintings</Translate>
              </Button>
            </Link>
          </Box>
        </Box>
      </CardMedia>
    </>
  );
};

export default HeroSection;
