import React, { useState, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Input, Button, Box, Typography } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';

const AdminLogin = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login, signupGoogle, role } = useAuth();
  const history = useHistory();
  console.log('role', role);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setError('');
      setLoading(true);

      await login(emailRef.current.value, passwordRef.current.value);
      history.push('/dashboard');
    } catch {
      setError('Failed to login, check username and password ');
    }

    setLoading(false);
  };

  const handleGoogleSingup = async () => {
    try {
      setError('');
      setLoading(true);
      await signupGoogle();
      history.push('/dashboard');
    } catch {
      setError('Failed to login with Google');
    }
    setLoading(false);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      mt="3rem"
    >
      <Typography variant="h3" paragraph>
        Login
      </Typography>
      {role !== 'admin' && <Alert severity="error">You are not admin</Alert>}
      {error && <Alert severity="error">{error}</Alert>}
      <form action="/" method="POST" onSubmit={(e) => handleSubmit(e)}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          maxWidth="16rem"
          style={{ gap: '1rem' }}
          my="1rem"
        >
          <Input
            inputRef={emailRef}
            name="email"
            type="text"
            placeholder="Email"
          />
          <Input
            inputRef={passwordRef}
            name="password"
            type="password"
            placeholder="Password"
          />
          <Button
            disabled={loading}
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
          >
            Login
          </Button>
          <Button
            disabled={loading}
            type="submit"
            color="primary"
            variant="contained"
            onClick={handleGoogleSingup}
            fullWidth
          >
            Login With Google
          </Button>
          <Typography>
            Forgot password?{' '}
            <Link to="/forgot-password" style={{ color: 'blue' }}>
              click here
            </Link>
          </Typography>
        </Box>
      </form>
    </Box>
  );
};

export default AdminLogin;
