import axios from "axios";
import React, { createContext, useEffect, useState } from "react";

export const ProductsAndPricesContext = createContext();

export const NUMBER_OF_ITEMS_IN_PAGE = 20;
const ProductsAndPricesContextProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);

  const [prices, setPrices] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER}/products`
      );
      const res = await axios.get(`${process.env.REACT_APP_SERVER}/prices`);
      setProducts(data);
      setPrices(res.data);

      const categories = data
        .slice(0, NUMBER_OF_ITEMS_IN_PAGE) //first page with Categories
        .map((m) => m.metadata)
        .map((paint) => paint.category);

      setCategories(() => [...new Set(categories)]);
      setLoading(false);
    })();
  }, []);

  return (
    <ProductsAndPricesContext.Provider
      value={{ products, prices, categories, loading, setCategories }}
    >
      {children}
    </ProductsAndPricesContext.Provider>
  );
};

export default ProductsAndPricesContextProvider;
