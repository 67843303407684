import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { countriesShipable } from "../../assets/data/countries";
import { Button } from "@material-ui/core";
import { Translate } from "react-auto-translate";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_SECRET_KEY);

const PurchaseButton = ({ price, available }) => {
  const [error, setError] = useState("");

  const handleClick = async (e) => {
    e.preventDefault();
    setError("");

    const stripe = await stripePromise;
    console.log("stripe", stripe);

    const { err } = await stripe
      .redirectToCheckout({
        lineItems: [
          {
            price,
            quantity: 1,
          },
        ],
        mode: "payment",
        successUrl: `${process.env.REACT_APP_DOMAIN}/checkout-success`,
        cancelUrl: `${process.env.REACT_APP_DOMAIN}/paintings`,
        shippingAddressCollection: {
          allowedCountries: countriesShipable,
        },
      })
      .catch((res) => console.log(res));

    if (err) {
      setError(error);
    }
  };

  return (
    <>
      <Button
        onClick={handleClick}
        variant="contained"
        color="primary"
        style={!available ? { display: "none" } : null}
      >
        <Translate>Purchase</Translate>
      </Button>
    </>
  );
};

export default PurchaseButton;
