import React from 'react'
import {  Route } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'
import AdminLogin from '../pages/AdminLogin'

const AdminRoute = ({component: Component, ...rest}) => {

    const { currentUser, role } = useAuth()

    return (
        <Route 
            {...rest}
            render={props => {
                return currentUser && role === 'admin' ? <Component {...props}/> : <AdminLogin/>
            }}
        />
    )
}

export default AdminRoute
