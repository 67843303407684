import {
  Box,
  Input,
  Button,
  MenuItem,
  Select,
  Typography,
  ImageList,
  ImageListItem,
  Modal,
  Snackbar,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import axios from 'axios';
import React, { useState } from 'react';
import { Translate } from 'react-auto-translate';
import { database, storage } from '../firebase';
import { v4 as uuidv4 } from 'uuid';

const AdminPage = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [file, setFile] = useState();
  const [deleteImage, setDelete] = useState({
    id: '',
    product_id: '',
    pice_id: '',
  });

  const [isModal, setIsModal] = useState(false);
  const [isSnackbar, setIsSnackbar] = useState(false);

  const [products, setProducts] = useState([]);
  const [prices, setPrices] = useState();
  const [categories, setCategories] = useState([]);

  const [name, setName] = useState('');
  const [category, setCategory] = useState([]);
  const [height, setHeight] = useState('');
  const [width, setWidth] = useState('');
  const [material, setMaterial] = useState('');
  const [replicable, setReplicable] = useState(false);
  const [condition, setCondition] = useState();
  const [timeTaken, setTimeTaken] = useState();
  const [price, setPrice] = useState();

  React.useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER}/products`
      );
      setProducts(data);

      const res = await axios.get(`${process.env.REACT_APP_SERVER}/prices`);
      setPrices(res.data);

      const categories = data
        .map((m) => m.metadata)
        .map((paint) => paint.category);

      setCategories(() => [...new Set(categories)]);
    })();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSnackbar(true);

    // FIREBASE
    const storageRef = storage
      .ref(`paintings`)
      .child(`${category}/${name}.jpg`);

    await storageRef.put(file);
    const imageUrl = await storageRef.getDownloadURL();

    const itemId = uuidv4();
    const timestamp = Date.now();

    const path = `paintings/${category}/${name}.jpg`

    const databaseRef = database.ref(`paintings`);
    databaseRef.push({
      id: itemId,
      timestamp,
      filename: file.name,
      name,
      category,
      url: imageUrl,
      path,
    });

    const data = new FormData();
    data.append('file', file);
    data.append('id', itemId);
    data.append('filename', file.name);
    data.append('timestamp', timestamp);
    data.append('name', name);
    data.append('category', category);
    data.append('height', height);
    data.append('width', width);
    data.append('material', material);
    data.append('replicable', replicable);
    data.append('condition', condition);
    data.append('time', timeTaken);
    data.append('price', price);
    data.append('url', imageUrl);

    setLoading(true);

    try {
      axios
        .post(`${process.env.REACT_APP_SERVER}/admin/upload`, data)
        .then(({ data }) => {
          const upload = data.data;
          if (data.upload) {
            try {
              axios
                .post(
                  `${process.env.REACT_APP_SERVER}/admin/upload/create`,
                  upload
                )
                .then((res) => console.log(res));
            } catch (error) {
              console.log('error', error.message);
            }
          }
        });
    } catch (error) {
      console.log('error', error.message);
    }
  };

  const deleteSotored = () => {
    const databaseRef = database.ref('paintings');
    databaseRef
      .orderByChild('id')
      .equalTo('e66afa0e-1959-4942-ae5e-858cfb4c5652')
      .once('value', (snap) => {
        const item = snap.val();
        console.log('item', item)
        snap.forEach((p) => {
          console.log(p.val());
          const storageRef = storage.ref(p.val().path);
          storageRef.delete();
        });
        databaseRef.child(Object.keys(item)[0]).remove();
      });
  };

  const handleDelete = async () => {
    const res = await axios.post(
      `${process.env.REACT_APP_SERVER}/admin/delete`,
      deleteImage
    );

    const databaseRef = database.ref('paintings');
    databaseRef
      .orderByChild('id')
      .equalTo(deleteImage.id)
      .once('value', (snap) => {
        const item = snap.val();
        console.log('item', item)
        snap.forEach((p) => {
          console.log(p.val());
          const storageRef = storage.ref(p.val().path);
          storageRef.delete();
        });
        databaseRef.child(Object.keys(item)[0]).remove();
      });
  };

  const getPriceId = (id) => {
    const price = prices.find((p) => p.product === id);
    return price.id;
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      mt="2rem"
    >
      {error && <Alert severity="error">{error}</Alert>}
      <form style={{ width: '80vw' }} onSubmit={handleSubmit}>
        <Box display="flex" flexDirection="column" style={{ gap: '1rem' }}>
          <input
            required
            type="file"
            accept=".jpg"
            onChange={(e) => setFile(e.target.files[0])}
          />
          <Input
            required
            type="text"
            placeholder="Name of Painting (will be diplayed at in Gallery and Checkout)"
            onChange={(e) => setName(e.target.value)}
          />
          <Select required onChange={(e) => setCategory(e.target.value)}>
            {categories.map((c) => (
              <MenuItem value={c}>{c}</MenuItem>
            ))}
          </Select>

          <Input
            required
            type="number"
            placeholder="Height (in cm)"
            onChange={(e) => setHeight(e.target.value)}
          />
          <Input
            required
            type="number"
            placeholder="Width (in cm)"
            onChange={(e) => setWidth(e.target.value)}
          />
          <Input
            required
            type="text"
            placeholder="Material"
            onChange={(e) => setMaterial(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                onChange={() => setReplicable(!replicable)}
              />
            }
            label="Can it be replicated? (tick)"
          />
          <Input
            required
            type="text"
            placeholder="Condition (e.g. good, warn)"
            onChange={(e) => setCondition(e.target.value)}
          />
          <Input
            required
            type="number"
            placeholder="TimeTaken (in hours)"
            onChange={(e) => setTimeTaken(e.target.value)}
          />
          <Input
            required
            type="number"
            placeholder="Price in GBP pence (e.g £200 write 20000)"
            onChange={(e) => setPrice(e.target.value)}
          />

          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            color="primary"
          >
            <Translate>Submit</Translate>
          </Button>
        </Box>
      </form>
      <Snackbar
        open={isSnackbar}
        autoHideDuration={6000}
        onClose={() => setIsSnackbar(false)}
      >
        <Alert severity="success">File Upload</Alert>
      </Snackbar>
      <Box m="4rem">
        <ImageList rowHeight="100%" gap={24}>
          {products.map((p) => (
            <ImageListItem
              key={p.metadata.id}
              cols={{ xs: 1, sm: 2, md: 3, lg: 4 }}
            >
              <Box
                component="img"
                src={p.metadata.url}
                maxHeight="25vh"
                maxWidth="25vw"
                border="4px solid rgba(166, 150, 115, 1)"
                p="4px"
              />
              <Box my="1rem">
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-end"
                  style={{ gap: '1rem' }}
                >
                  <Button
                    variant="outlined"
                    color="secondary"
                    onClick={() => {
                      setDelete({
                        id: p.metadata.id,
                        product_id: p.id,
                        price_id: getPriceId(p.id),
                      });
                      setIsModal(!isModal);
                    }}
                  >
                    <Translate>Delete</Translate>
                  </Button>
                </Box>
                <Modal open={isModal}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    flexDirection="column"
                    height="80vh"
                    bgcolor="white"
                    textAlign="center"
                  >
                    <Typography variant="h5">
                      Are You Sure You Want To Delete This?
                    </Typography>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => setIsModal(!isModal)}
                    >
                      <Translate>Cancel</Translate>
                    </Button>
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        setIsModal(!isModal);
                        handleDelete();
                      }}
                    >
                      <Translate>Delete</Translate>
                    </Button>
                  </Box>
                </Modal>
              </Box>
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
    </Box>
  );
};

export default AdminPage;
