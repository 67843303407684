import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import SideDrawer from '../SideDrawer/SideDrawer';
import {  NavLink } from 'react-router-dom';
import { Hidden, Box } from '@material-ui/core';
import { section, responsiveFontSize } from '../../theme';
import { Translate } from 'react-auto-translate';
import ReactFlagsSelect from 'react-flags-select';
import { useAuth } from '../../contexts/AuthContext';
import { useHistory } from 'react-router-dom'


export default function Navbar({ toFrench, setToFrench }) {
  const [hoverLogout, setHoverLogout] = useState(false);
  const history = useHistory()

  const {logout} = useAuth()

  const handleLogout = async () => {
    try {
      await logout();
      history.push('/login');
    } catch (err) {
      console.log('failed to logout ', err)
    }
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent={{ sm: 'space-between' }}
        alignItems="center"
        px={section.paddingX}
        py={{ xs: '0.5rem', md: '1rem' }}
        bgcolor="primary.main"
        boxShadow="5"
      >
        <Box flexGrow={12} textAlign={{ xs: 'center', sm: 'left' }}>
          <Typography
            color="textSecondary"
            variant="h5"
            style={{ whiteSpace: 'nowrap' }}
          >
            <NavLink to="/" activeStyle={{color: 'white'}}>Sacko Diéli Makan</NavLink>
          </Typography>
        </Box>
        <Hidden smUp>
          <Box flexGrow={0}>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="open drawer"
              aria-label="show more"
              aria-haspopup="true"
            >
              <SideDrawer toFrench={toFrench} setToFrench={setToFrench} />
            </IconButton>
          </Box>
        </Hidden>

        <Hidden xsDown>
          <Box display="flex">
            <Box
              component="ul"
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              aria-label="nav list"
              style={{ gap: '0.5rem' }}
            >
              <Box component="li" p="0.5rem" fontSize={responsiveFontSize}>
                <NavLink to="/paintings" >
                  <Translate>Paintings</Translate>
                </NavLink>
              </Box>
              <Box component="li" p="0.5rem" fontSize={responsiveFontSize}>
                <NavLink to="/about" >
                  <Translate>About</Translate>
                </NavLink>
              </Box>
              <Box component="li" p="0.5rem" fontSize={responsiveFontSize}>
                <NavLink to="/contact" >
                  <Translate>Contact Us</Translate>
                </NavLink>
              </Box>
              {/* <Box component="li" p="0.5rem">
                <NavLink to="/cart" >
                  <Badge badgeContent={1} color="secondary">
                    <ShoppingBasketIcon color="" />
                  </Badge>
                </NavLink>
              </Box> */}
              <Box
                component="li"
                p="0.5rem"
                onMouseEnter={() => setHoverLogout(true)}
                onMouseLeave={() => setHoverLogout(false)}
              >
                {/* <NavLink to="/profile">
                  <AccountCircle></AccountCircle>
                </NavLink> */}
                <Box
                  display={hoverLogout ? 'block' : 'none'}
                  position="absolute"
                  bgcolor="white"
                  p="0.75rem"
                  mt="0.25rem"
                  borderRadius={5}
                  boxShadow={3}
                >
                    <Box onClick={handleLogout} color="black" style={{cursor: 'pointer'}}>
                      <Typography variant="body1">Logout</Typography>
                    </Box>
                </Box>
              </Box>
              <Box component="li" p="0.5rem">
                <ReactFlagsSelect
                  countries={['GB', 'FR']}
                  customLabels={{ GB: 'English', FR: 'French' }}
                  selected={toFrench}
                  onSelect={(code) => setToFrench(code)}
                  placeholder="Language"
                />
              </Box>
            </Box>
          </Box>
        </Hidden>
      </Box>
    </>
  );
}
