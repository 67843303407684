import React from 'react';
import emailjs from 'emailjs-com';
import { Button, CardMedia, TextField, Typography } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import contactUs from '../assets/images/contactUs.jpeg';
import Box from '@material-ui/core/Box';
import { section } from '../theme';
import { Translate } from 'react-auto-translate';


const Contact = () => {
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_qarwfqv',
        `${process.env.REACT_APP_EMAILJS_TEMPLATE_ID}`,
        e.target,
        `${process.env.REACT_APP_EMAILJS_USER_ID}`
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    //reset
    e.target.user_name.value = '';
    e.target.user_email.value = '';
    e.target.message.value = '';
  };

  return (
    <>
      <CardMedia
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url(${contactUs})`,
        }}
      >
        <Box
          display="flex"
          textAlign="center"
          justifyContent="center"
          alignItems="center"
          color="common.white"
          width="100%"
          height="70vh"
        >
          <Typography variant="h1">
            <Translate>Contact Us</Translate>
          </Typography>
        </Box>
      </CardMedia>
      <form onSubmit={sendEmail}>
        <Box
          display="flex"
          flexDirection="column"
          px={section.paddingX}
          my="4rem"
          style={{ gap: '2rem' }}
        >
          <Typography variant="h4"><Translate>Please Enter Your Message</Translate></Typography>
          <TextField
            required
            type="text"
            name="user_name"
            placeholder="Enter name..."
          />
          <TextField
            required
            type="email"
            name="user_email"
            placeholder="Enter email..."
          />
          <TextField
            required
            name="message"
            placeholder="Enter message..."
            multiline
            rows={10}
          />
          <Button type="submit" variant="outlined" endIcon={<SendIcon />}>
            <Typography variant="body1"><Translate>Send</Translate></Typography>
          </Button>
        </Box>
      </form>
    </>
  );
};

export default Contact;
