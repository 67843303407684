import React, { useState, useEffect, useContext } from 'react';
import { createContext } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { auth } from '../firebase';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(true);

  const signup = (email, password) => {
    return auth.createUserWithEmailAndPassword(email, password);
  };

  useEffect(() => {
    const unsub = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      setLoading(false);
    });
    return unsub;
  }, []);

  useEffect(() => {
    if (currentUser) {
      setLoading(true);
      const unsubscribe = auth.currentUser.getIdTokenResult().then((token) => {
        setRole(token.claims.role);
        setLoading(false);
      });
      return unsubscribe;
    }
  }, [currentUser]);

  const signupGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebase.auth().signInWithPopup(provider);
  };

  const login = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  };

  const logout = () => {
    return auth.signOut();
  };

  const resetPassword = (email) => {
    return auth.sendPasswordResetEmail(email);
  };

  const value = {
    currentUser,
    role,
    signup,
    login,
    logout,
    resetPassword,
    signupGoogle,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
