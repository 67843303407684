import { createTheme, responsiveFontSizes } from '@material-ui/core';

let theme = createTheme({
  palette: {
    primary: {
      main: 'rgba(166, 150, 115, 1)',
      contrastText: '#fff',
    },
    text: {
      primary: 'rgb(0,0,0)',
      secondary: 'white',
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        backgroundColor: 'rgba(166, 150, 115, 1)',
        borderRadius: 20,
        '&:hover': {
          backgroundColor: 'rgb(114, 94, 69)',
          color: 'white'
        }
      }
    },
    MuiImageListItem: {
      item: {
        overflow: 'visible'
      }
    }
  }  
});

theme = responsiveFontSizes(theme)

export const section = {
  paddingX: {
    xs: 2,
    sm: 4,
    md: 12,
    lg: 24,
  },
  paddingY: {
    xs: 1,
    sm: 2,
    md: 4,
    lg: 8,
  },
};

export const responsiveFontSize = {
  xs: '0.75rem',
  sm: '1rem',
  md: '1.25rem',
}

export default theme;
