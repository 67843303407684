import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Input, Button, Box, Typography } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import { Alert } from '@material-ui/lab';

const SignUp = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const { signup, signupGoogle } = useAuth();

  const nameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();

  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (passwordRef.current.value !== passwordConfirmRef.current.value) {
      return setError('Passwords do not match');
    }

    try {
      setError('');
      setLoading(true);
      await signup(emailRef.current.value, passwordRef.current.value);
      history.push('/profile');
    } catch {
      setError('Failed to create account');
    }

    setLoading(false);
  };

  const handleGoogleSingup = async () => {
    try {
      setError('');
      setLoading(true);
      await signupGoogle();
      history.push('/profile');
    } catch {
      setError('Failed to signup with Google');
    }
    setLoading(false);
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      mt="3rem"
    >
      <Typography variant="h3" paragraph>
        Sign Up
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      <form action="/" method="POST" onSubmit={(e) => handleSubmit(e)}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          maxWidth="16rem"
          style={{ gap: '1rem' }}
          my="1rem"
        >
          <Input
            inputRef={nameRef}
            name="name"
            type="text"
            placeholder="Name"
          />
          <Input
            inputRef={emailRef}
            name="email"
            type="text"
            placeholder="Email"
          />
          <Input
            inputRef={passwordRef}
            name="password"
            type="password"
            placeholder="Password"
          />
          <Input
            inputRef={passwordConfirmRef}
            name="passwordConfirm"
            type="password"
            placeholder="Confirm password"
          />
          <Button
            disabled={loading}
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
          >
            Sign Up
          </Button>
        </Box>
        <Button
          disabled={loading}
          type="submit"
          color="primary"
          variant="contained"
          onClick={handleGoogleSingup}
          fullWidth

        >
          Sign Up With Google
        </Button>
      </form>

      <Box mt="1rem">
        <Typography color="textPrimary">
          Already have an account{' '}
          <Link
            to="/login"
            style={{ color: 'blue', textDecoration: 'underline' }}
          >
            login here
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default SignUp;
