import { useState } from "react";

import { ThemeProvider } from "@material-ui/core";
import theme from "./theme";
import Navbar from "./components/Navbar/Navbar";
import { Route, Switch } from "react-router";
import Paintings from "./pages/Paintings";
import Homepage from "./pages/Homepage";
import NotFound from "./pages/NotFound";
import SignUp from "./pages/SignUp";
import Login from "./pages/Login";
import Footer from "./components/Footer/Footer";
import About from "./pages/About";
import Contact from "./pages/Contact";
import { Translator } from "react-auto-translate";
import cacheProvider from "./modules/cacheProvider";
import Forgotpassword from "./pages/Forgotpassword";
import Dashboard from "./pages/Dashboard";
import AdminLogin from "./pages/AdminLogin";
import AdminRoute from "./util/AdminRoute";
import CheckoutSuccess from "./pages/CheckoutSuccess";
import ProductsAndPricesContextProvider from "./contexts/ProductsAndPricesContextProvider";

function App() {
  const [toFrench, setToFrench] = useState("en");

  return (
    <ProductsAndPricesContextProvider>
      <Translator
        cacheProvider={cacheProvider}
        to={toFrench}
        from="en"
        googleApiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      >
        <ThemeProvider theme={theme}>
          <>
            <Navbar setToFrench={setToFrench} nav={toFrench} />

            <Switch>
              <AdminRoute path="/dashboard" component={Dashboard} />
              <Route exact path="/" component={Homepage} />
              <Route path="/paintings" component={Paintings} />
              <Route path="/about" component={About} />
              <Route path="/contact" component={Contact} />
              <Route path="/signup" component={SignUp} />
              <Route path="/login" component={Login} />
              <Route path="/admin" component={AdminLogin} />
              <Route path="/forgot-password" component={Forgotpassword} />
              <Route path="/checkout-success" component={CheckoutSuccess} />

              <Route component={NotFound} />
            </Switch>
            <Footer />
          </>
        </ThemeProvider>
      </Translator>
    </ProductsAndPricesContextProvider>
  );
}

export default App;
