import React, { useContext, useEffect, useState } from "react";
import PaintCard from "../components/PaintCard/PaintCard";
import {
  Box,
  Button,
  ImageList,
  ImageListItem,
  Typography,
} from "@material-ui/core";
import { section } from "../theme";
import { Translate } from "react-auto-translate";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  NUMBER_OF_ITEMS_IN_PAGE,
  ProductsAndPricesContext,
} from "../contexts/ProductsAndPricesContextProvider";

const Paintings = () => {
  const { products, prices, categories, setCategories } = useContext(
    ProductsAndPricesContext
  );

  const [previousPage, setPreviousPage] = useState(0);
  const [nextPage, setNextPage] = useState(NUMBER_OF_ITEMS_IN_PAGE);
  const paginated = [...products].slice(previousPage, nextPage);

  const handlePages = (previous, next) => {
    setPreviousPage(previous);
    setNextPage(next);

    const categories = [...products]
      .slice(previous, next)
      .map((m) => m.metadata)
      .map((paint) => paint.category);

    setCategories(() => [...new Set(categories)]);
    window.scrollTo(0, 0);
  };
  if (!products || !prices)
    return (
      <Box display="flex" justifyContent="center" p="400px">
        <CircularProgress />
      </Box>
    );

  return (
    <Box component="section" my={section.paddingY} mx={section.paddingX}>
      <Typography variant="h3">
        <Translate>Paintings</Translate>
      </Typography>
      {categories.map((title) => (
        <>
          <Box display="flex" alignItems="flex-end" mt="5rem" mb="2rem">
            <Typography variant="h4">
              <Box
                component="span"
                borderTop="1px solid rgba(166, 150, 115, 1)"
                borderLeft="1px solid rgba(166, 150, 115, 1)"
                width={30}
                height={30}
                position="absolute"
                m="-3px 0 0 -10px"
              />
              <Translate>{title}</Translate>
            </Typography>
            <Box
              bgcolor="primary.main"
              height="3px"
              width={{ xs: "50%", lg: "30%" }}
              mb="0.7rem"
              ml="1rem"
            />
          </Box>
          <ImageList rowHeight="100%" gap={24}>
            {paginated.map((p) =>
              p.metadata.category === title ? (
                <ImageListItem key={p.id} cols={{ xs: 1, sm: 2, md: 3, lg: 4 }}>
                  <PaintCard
                    id={p.id}
                    product={p}
                    price={prices.find((price) => price.product === p.id)}
                  />
                </ImageListItem>
              ) : null
            )}
          </ImageList>
        </>
      ))}
      <Box display="flex" justifyContent="space-evenly" mt="100px">
        <Button
          style={{
            width: "10rem",
            display: `${previousPage === 0 ? "none" : "block"}`,
          }}
          variant="outlined"
          onClick={() =>
            handlePages(
              previousPage - NUMBER_OF_ITEMS_IN_PAGE,
              nextPage - NUMBER_OF_ITEMS_IN_PAGE
            )
          }
        >
          Back
        </Button>
        <Button
          style={{
            width: "10rem",
            display: `${products.length <= nextPage ? "none" : "block"}`,
          }}
          variant="contained"
          onClick={() =>
            handlePages(
              previousPage + NUMBER_OF_ITEMS_IN_PAGE,
              nextPage + NUMBER_OF_ITEMS_IN_PAGE
            )
          }
        >
          Next
        </Button>
      </Box>
      <Typography
        style={{ opacity: 0.8, textAlign: "center", marginTop: "130px" }}
      >
        *Please note that the dimension of a paiting may vary by 1-2cm
      </Typography>
    </Box>
  );
};

export default Paintings;
