import { Box, Typography } from '@material-ui/core';
import { Translate } from 'react-auto-translate';

import React, { useRef, useEffect } from 'react';
import lottie from 'lottie-web';

const CheckoutSuccess = () => {
  const container = useRef(null);

  useEffect(() => {
    lottie.loadAnimation({
      container: container.current,
      renderer: 'svg',
      autoplay: true,
      loop: false,
      animationData: require('../assets/lottie/tick.json'),
    });
  }, []);
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="70vh"
    >
      <Typography variant="h1">
        <Translate>Success</Translate>
      </Typography>
      <div ref={container}></div>
    </Box>
  );
};

export default CheckoutSuccess;
