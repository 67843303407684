import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import visaLogo from '../../assets/logo/visaLogo.webp';
import mastercardLogo from '../../assets/logo/mastercardLogo.webp';
import googlepayLogo from '../../assets/logo/googlepayLogo.png';
import applepayLogo from '../../assets/logo/applepayLogo.png';
import americanExpressLogo from '../../assets/logo/americanExpressLogo.png';
import { Link } from 'react-router-dom';
import { section } from '../../theme';
import { Translate } from 'react-auto-translate';

const footerLink = {
  color: 'black',
};

const Footer = () => {
  return (
    <footer>
      <Box px={section.paddingX} py={section.paddingY}>
        <Grid container>
          {/* contact */}
          <Grid item xs={12} sm={4}>
            <Box component="h3" mt="2rem">
              <Translate>Contact Us</Translate>
            </Box>
            <Box
              bgcolor="common.black"
              height="3px"
              width="50%"
              mt="1rem"
              mb="2rem"
            />
            <Box
              display="flex"
              flexDirection="column"
              style={{ gap: '0.5rem' }}
            >
              <p>Rue Franele Diarra,</p>
              <p>Bamako,</p>
              <p>Mali</p>
              <br />
              <p>info@sacko.com</p>
            </Box>
          </Grid>
          {/* view */}
          <Grid item xs={12} sm={4}>
            <Box component="h3" mt="2rem">
              <Translate>View</Translate>
            </Box>
            <Box
              bgcolor="common.black"
              height="3px"
              width="50%"
              mt="1rem"
              mb="2rem"
            />
            <Box
              display="flex"
              flexDirection="column"
              style={{ gap: '0.5rem' }}
            >
              <Link style={footerLink} to="/about">
                <Translate>About</Translate>
              </Link>
              <Link style={footerLink} to="/paintings">
                <Translate>Paintings</Translate>
              </Link>
            </Box>
          </Grid>
          {/* terms and payment logos */}
          <Grid item xs={12} sm={4}>
            <Box
              mt={{ xs: '2rem', sm: '6.7rem' }}
              display="flex"
              flexDirection="column"
              style={{ gap: '0.5rem' }}
            >
              <Link style={footerLink} to="/">
                <Translate>Terms and Conditions</Translate>
              </Link>
              <Link style={footerLink} to="/">
                <Translate>Privacy Policy</Translate>
              </Link>
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              mt="2rem"
              style={{ gap: '0.5rem' }}
            >
              {[
                visaLogo,
                mastercardLogo,
                americanExpressLogo,
                applepayLogo,
                googlepayLogo,
              ].map((imgItem, index) => (
                <Box height={{ xs: '1.5rem', sm: '2rem' }} key={index}>
                  <img height="100%" src={imgItem} alt="" />
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box bgcolor="common.black" height="1px" width="100%" mt="1rem" />
      {/* copyright */}
      <Box textAlign="center" py="1.5rem">
        <p>
          © {new Date().getFullYear() > 2021 ? '2021-' : ''}{' '}
          {new Date().getFullYear()} Sacko Diéli Makan Website{' '}
          <Translate> Powered by </Translate>{' '}
          <a style={{ color: 'black' }} href="https://www.carloswebs.com">
            Carlos
          </a>
        </p>
      </Box>
    </footer>
  );
};

export default Footer;
