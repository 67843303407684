import { Button, CardMedia, Grid, Typography } from '@material-ui/core';
import sackoProfile from '../assets/images/sackoProfile.jpeg';
import sackoPainting from '../assets/images/sackoPainting.jpeg';
import oilPainting from '../assets/images/40 x 61.jpeg';
import Box from '@material-ui/core/Box';
import FormatQuoteRoundedIcon from '@material-ui/icons/FormatQuoteRounded';
import { section, responsiveFontSize } from '../theme';
import { Link } from 'react-router-dom';
import { ArrowRightAlt } from '@material-ui/icons';
import { Translate } from 'react-auto-translate';

const About = () => {
  return (
    <>
      <CardMedia image={sackoProfile}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          textAlign="center"
        >
          <Box
            style={{ background: 'rgba(0,0,0,0.5)', borderRadius: '5px' }}
            p={{ xs: 2, md: 6 }}
          >
            <Typography variant="h5" color="textSecondary">
              <Translate>Painter | Mali</Translate>
            </Typography>
            <Typography variant="h3" color="textSecondary">
              <Translate>Sacko Diéli Makan</Translate>
            </Typography>
          </Box>
        </Box>
      </CardMedia>

      <Box px={section.paddingX} py={section.paddingY}>
        {/* quote */}
        {/* <Typography variant="h6" style={{ fontWeight: 600 }}>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium
          ea debitis vero libero ex. Ex rerum voluptatum voluptates sint
          laudantium, asperiores consectetur harum dolore velit est sit
          aspernatur ad quisquam.
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          fontSize={{ xs: '3rem', sm: '4rem', md: '5rem' }}
        >
          <FormatQuoteRoundedIcon color="primary" fontSize="inherit" />
        </Box> */}
      </Box>

      <Box px={section.paddingX} py={section.paddingY}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Box
              component="img"
              src={sackoPainting}
              height="80vh"
              width="100%"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              component="p"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography variant="body1" paragraph>
                <Translate>
                  Diéli Makan SACKO 1967 - 2020 Born in 1967 in Bamako (Mali),
                  in a renowned family of griots. Son of Seiba Mohammed Sacko,
                  guitarist , and son of Madié Soumano, president of the female
                  griot of Bamako and sister of Bakang Soumano, chief of the
                  griot, she is is also the grand-daughter of Bakary Soumano.
                  The griot blood flowing through his veins, Diéli Makan was
                  attracted to Art from an early age and in particular the Art
                  of Dance.
                </Translate>
                <br />
                <br />
                <Translate>
                  From 10 years old, he takes part in all the primary schools
                  dance competition in the area of Bamako. His talent and fierce
                  will to succeed were not left unnoticed. In 1983, when he was
                  16 years old, he succeed at the inter- district selection and
                  enters the company of “commune IV” of Bamako ( municipality
                  IV). This is the beginning of an impressive learning journey
                  where the youth of the company go from village to village to
                  discover and learn about the traditional dance and music of
                  each ethnic group.
                </Translate>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={4} direction="row-reverse">
          <Grid item xs={12} sm={6}>
            <Box component="img" src={oilPainting} height="80vh" width="100%" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              component="p"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography variant="body1" paragraph>
                <Translate>
                  {' '}
                  Step after step, Diéli Makan climbs the ladder from the
                  district of Bamako to the prestigious Ballet National du Mali
                  in 1986. In 1984 and 1986, he takes part twice in the
                  Biennales de Musiques et Danse de Bamako, the biggest event
                  about traditional Arts of Africa.
                </Translate>
                <br />
                <br />
                <Translate>
                  After his passage in the Ballet National, Diéli Makan joins
                  and creates several dance companies, first in Mali and then in
                  France where he directs and creates a dozen of ballet ;
                  including the acclaimed « Shaka » in 1997, « Un africain à
                  Paris » in 1998 « Folo folo » in 2008 or « DON KORO » in 2009.
                </Translate>
                <br />
                <br />
                <Translate>
                  {' '}
                  He also takes part in many exhibitions displaying his
                  paintings and bogolan works, traditional Malian painting on
                  fabrics. In the field of the fine Arts, he designs oil
                  paintings and creates the set and costume for each of the show
                  he designs.
                </Translate>
                <br />
                <br />
                <Translate>
                  In 2005, Diéli Makan Sacko creates «La Maison des Arts de
                  Bamako» in Mali, a school of traditional Arts approved by the
                  Ministère de la Culture du Mali ( Ministry of Cultural Affairs
                  of Mali), in order to pass on this culture so dear to his
                  heart.
                </Translate>
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <br />
        <Link to="/paintings">
          <Button
            fullWidth
            variant="contained"
            color="primary"
            endIcon={<ArrowRightAlt>send</ArrowRightAlt>}
          >
            <Translate>View Paintings</Translate>
          </Button>
        </Link>
      </Box>
    </>
  );
};

export default About;
