import React from "react";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Translate } from "react-auto-translate";
import PurchaseButton from "../PurchaseButton/PurchaseButton";

const PaintCard = ({ product, price }) => {
  const available = JSON.parse(product?.metadata?.available);

  return (
    <>
      <Box
        component="img"
        src={`${process.env.REACT_APP_GOOGLE_CLOUD_STORAGE_URL}${product.metadata.url}`}
        maxHeight="70vh"
        maxWidth="80vw"
        border="4px solid rgba(166, 150, 115, 1)"
        p="4px"
      />
      <Box
        component="span"
        position="absolute"
        top="-10px"
        right="-10px"
        bgcolor="rgba(0,0,0,0.5)"
        p={2}
        display={available ? "none" : "block"}
      >
        <Typography variant="h5" color="textSecondary">
          <Translate>{available ? null : "Copy Available"}</Translate>
        </Typography>
      </Box>
      <Box my="1rem">
        <Box>
          <Typography variant="h6" align="right" paragraph>
            {product?.name} cm*, £
            {price?.unit_amount === undefined
              ? "TBA"
              : price?.unit_amount / 100}
          </Typography>
          <Typography
            variant="body1"
            style={{ textTransform: "capitalize", color: "grey" }}
            align="right"
            paragraph
          >
            <Translate>{product?.metadata?.material},</Translate>
            <Translate>
              {product?.metadata?.material === "bogolan"
                ? " Cotton Fabric"
                : product?.metadata?.material === "oil"
                ? " Oil On Canvas"
                : null}
            </Translate>
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          style={{ gap: "1rem" }}
        >
          {product?.metadata.available ? (
            <PurchaseButton
              product={product}
              price={price?.id}
              available={available}
            />
          ) : null}
          <Link to="contact">
            <Button variant="contained" color="primary">
              <Translate>Enquire About a Copy</Translate>
            </Button>
          </Link>
        </Box>
      </Box>
    </>
  );
};

export default PaintCard;
