import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import AccountCircle from '@material-ui/icons/AccountCircle';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

import MenuIcon from '@material-ui/icons/Menu';
import { Brush, Info } from '@material-ui/icons';
import EmailIcon from '@material-ui/icons/Email';

import { Link as RouterLink } from 'react-router-dom';
import { Translate } from 'react-auto-translate';
import { Box, Typography } from '@material-ui/core';
import ReactFlagsSelect from 'react-flags-select';
import { useAuth } from '../../contexts/AuthContext';
import { useHistory } from 'react-router-dom';

const ListItemLink = ({ icon, primary, to }) => {
  const renderLink = React.useMemo(
    () =>
      React.forwardRef(function Link(itemProps, ref) {
        return <RouterLink to={to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [to]
  );

  return (
    <li>
      <ListItem button component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
};

const useStyles = makeStyles({
  list: {
    width: 250,
    backgroundColor: 'rgba(166, 150, 115, 1)',
    height: '100%',
    color: 'white',
  },
  fullList: {
    width: 'auto',
  },
});

export default function SideDrawer({ toFrench, setToFrench }) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
    right: false,
  });

  const history = useHistory();

  const { logout } = useAuth();

  const handleLogout = async () => {
    try {
      await logout();
      history.push('/login');
    } catch (err) {
      console.log('failed to logout ', err);
    }
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className={classes.list} aria-label="nav list">
        <ListItemLink
          to="/paintings"
          primary={<Translate>Paintings</Translate>}
          icon={<Brush />}
        />
        <ListItemLink
          to="/about"
          primary={<Translate>About</Translate>}
          icon={<Info />}
        />
        <ListItemLink
          to="/contact"
          primary={<Translate>Contact Us</Translate>}
          icon={<EmailIcon />}
        />
        {/* <ListItemLink
          to="/cart"
          primary={<Translate>Basket</Translate>}
          icon={
            <Badge badgeContent={1} color="secondary">
              <ShoppingBasketIcon color="" />
            </Badge>
          }
        /> */}
        {/* <ListItemLink
          to="/profile"
          primary={<Translate>Profile</Translate>}
          icon={<AccountCircle />}
        /> */}
        <Box onClick={handleLogout} ml='4.5rem' mb='1rem'>
          <Translate>Logout</Translate>
        </Box>
        <Box onClick={(e) => e.stopPropagation()} color="black">
          <ReactFlagsSelect
            countries={['GB', 'FR']}
            customLabels={{ GB: 'English', FR: 'French' }}
            selected={toFrench}
            onSelect={(code) => setToFrench(code)}
            placeholder="Language"
          ></ReactFlagsSelect>
        </Box>
      </List>
      <Divider />
    </div>
  );

  return (
    <Box color="white">
      <Button onClick={toggleDrawer('right', true)} color="inherit">
        <MenuIcon color="inherit" />
      </Button>
      <Box>
        <Drawer
          anchor="right"
          open={state['right']}
          onClose={toggleDrawer('right', false)}
        >
          {list('right')}
        </Drawer>
      </Box>
    </Box>
  );
}
