import React from 'react';
import HeroSection from '../components/HeroSection/HeroSection';
import CarouselCotainer from '../components/CarouselCotainer/CarouselCotainer';
import AboutSection from '../components/AboutSection/AboutSection';

const Homepage = () => {
  return (
    <>
      <HeroSection />
      <CarouselCotainer />
      <AboutSection />
    </>
  );
};

export default Homepage;
