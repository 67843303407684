import {
  Button,
  CardMedia,
  Grid,
  Typography,
} from '@material-ui/core';
import sackoProfile from '../../assets/images/sackoProfile.jpeg';
import sackoPainting from '../../assets/images/sackoPainting.jpeg';
import Box from '@material-ui/core/Box';
import FormatQuoteRoundedIcon from '@material-ui/icons/FormatQuoteRounded';
import { section } from '../../theme';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { Link } from 'react-router-dom';
import {Translate} from 'react-auto-translate'


const AboutSection = () => {
  return (
    <>
      <CardMedia image={sackoProfile}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          height="100vh"
          textAlign="center"
        >
          <Box
            style={{ background: 'rgba(0,0,0,0.5)', borderRadius: '5px' }}
            p={{ xs: 2, md: 6 }}
          >
            <Box
              fontSize={{
                xs: 'h6.fontSize',
                sm: 'h5.fontSize',
                md: 'h3.fontSize',
              }}
              color="primary.contrastText"
            >
              <Translate>Painter | Mali</Translate>
            </Box>
            <Box
              fontSize={{
                xs: 'h4.fontSize',
                sm: 'h3.fontSize',
                md: 'h2.fontSize',
              }}
              color="primary.contrastText"
            >
              <Translate>Sacko Diéli Makan</Translate>
            </Box>
          </Box>
        </Box>
      </CardMedia>

      <Box px={section.paddingX} py={section.paddingY}>
        {/* quote */}
        {/* <Typography variant="h6" style={{fontWeight: 600}}>
          <Translate>Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium
          ea debitis vero libero ex. Ex rerum voluptatum voluptates sint
          laudantium, asperiores consectetur harum dolore velit est sit
          aspernatur ad quisquam.</Translate>
        </Typography>
        <Box
          display="flex"
          justifyContent="center"
          fontSize={{ xs: '3rem', sm: '4rem', md: '5rem' }}
        >
          <FormatQuoteRoundedIcon color="primary" fontSize="inherit" />
        </Box> */}
      </Box>

      <Box px={section.paddingX} py={section.paddingY}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Box
              component="p"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height="100%"
            >
              <Typography variant="body1" paragraph>
                <Translate>Diéli Makan SACKO 1967 - 2020 Born in 1967 in Bamako (Mali), in
                a renowned family of griots. Son of Seiba Mohammed Sacko,
                guitarist , and son of Madié Soumano, president of the female
                griot of Bamako and sister of Bakang Soumano, chief of the
                griot, she is is also the grand-daughter of Bakary Soumano. The
                griot blood flowing through his veins, Diéli Makan was attracted
                to Art from an early age and in particular the Art of Dance.</Translate>
              </Typography>
              <Typography variant="body1" paragraph>
                <Translate>From 10 years old, he takes part in all the primary schools
                dance competition in the area of Bamako. His talent and fierce
                will to succeed were not left unnoticed. In 1983, when he was 16
                years old, he succeed at the inter- district selection and
                enters the company of “commune IV” of Bamako ( municipality IV).
                This is the beginning of an impressive learning journey where
                the youth of the company go from village to village to discover
                and learn about the traditional dance and music of each ethnic
                group.</Translate>
              </Typography>
              <Link to="/about">
                <Button fullWidth variant="outlined" endIcon={<MenuBookIcon />}>
                  <Translate>Learn More</Translate>
                </Button>
              </Link>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardMedia image={sackoPainting}>
              <Box height="80vh" />
            </CardMedia>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default AboutSection;
