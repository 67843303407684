import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import CarouselDetails from "../CarouselDetails/CarouselDetails";
import { Box } from "@material-ui/core";
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress";

const CarouselCotainer = () => {
  const [toggle, setToggle] = useState(false);
  const [products, setProducts] = React.useState([]);
  const [paintingPostion, setPaintingPostion] = useState({});
  const [prices, setPrices] = useState();

  useEffect(() => {
    (async () => {
      const { data } = await axios.get(
        `${process.env.REACT_APP_SERVER}/products`
      );
      setProducts(data);

      const res = await axios.get(`${process.env.REACT_APP_SERVER}/prices`);
      setPrices(res.data);
    })();
  }, []);

  useEffect(() => {
    const body = document.querySelector("body");
    body.style.overflow = toggle ? "hidden" : null;
  }, [toggle]);

  const handleToggle = () => setToggle(!toggle);

  if (!products || !prices) return <CircularProgress />;

  return (
    <>
      <Box bgcolor="rgba(166, 150, 115, 0.5)">
        <Carousel
          autoPlay={false}
          infiniteLoop={true}
          dynamicHeight={false}
          showIndicators={true}
          centerMode={false}
          showStatus={false}
          showThumbs={false}
          onClickItem={(e) => {
            setPaintingPostion(e);
            setToggle(!toggle);
          }}
        >
          {products.map((paint) => (
            <Box
              component="img"
              src={`${process.env.REACT_APP_GOOGLE_CLOUD_STORAGE_URL}${paint?.metadata?.url}`}
              key={paint?.id}
              display="flex"
              mx="0.5rem"
              my="0.25rem"
              maxHeight="80vh"
              width={{ xs: "100%", sm: "auto!important" }}
              height="100%"
            />
          ))}
        </Carousel>
      </Box>
      {/* {toggle && (
        <CarouselDetails
          products={products}
          prices={prices}
          toggle={handleToggle}
          paintingPostion={paintingPostion}
        />
      )} */}
    </>
  );
};

export default CarouselCotainer;
